import React, { useEffect, useState } from "react";
import "./HomeStudent.scss";
import HeaderComponent from "../../../components/HeaderComponent/HeaderComponent";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import enem from "../../../assets/images/galera-do-enem.png";
import { Video } from "../../../components/VideoComponent/VideoComponent";
import PendulumCard from "../../../components/PendulumCard/PendulumCard";
import { Sidebar } from "../../../components/Sidebar/Sidebar";
import { getFileURL } from "../../../databases/storage";
import { auth } from "../../../databases/firebaseConfig";

const HomePageStudent: React.FC = () => {
  const navigate = useNavigate();
  const { slug } = useParams<{ slug: string }>();
  const { state } = useLocation();
  const uid = state?.uid || localStorage.getItem('code');
  const curso = state?.curso || 'enem';
  const role = state?.role ? state?.role : "student";
  const nome = state?.userName ? state?.userName : "Estudante";
  const partesDoNome = nome.split(" ");
  const logoUrl = state?.logoUrl;
  const [imgUrl, setImgUrl] = useState("");

  // Check if the user is already signed in
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) {
        navigate("/");
      }
    });

    // Cleanup the subscription when the component unmounts
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    // Check if the user is already signed in
    
    // Carrega a imagem de fundo ao montar o componente
    const loadBackgroundImage = async () => {
      try {
        const imageUrl = await getFileURL("students-page/background");
        // Aplique a URL no CSS
        document.documentElement.style.setProperty(
          "--background-image-student",
          "url(" + (imageUrl) + ")"
        );

        const imageUrlMobile = await getFileURL(
          "students-page/background-mobile"
        );
        document.documentElement.style.setProperty(
          "--background-image-mobile-student",
          "url(" + (imageUrlMobile) + ")"
        );

        setImgUrl(imageUrl);
      } catch (error) {
        console.error("Erro ao carregar a imagem de fundo:", error);
      }
    };

    // Carrega a imagem de fundo ao montar o componente
    loadBackgroundImage();
  }, []);

  const isFirstPageValue = role === 'adm' ? false : true;

  return (
    <div className="student-page">
      <div className={`student-page_container ${curso}`}>
      <HeaderComponent
        urlPhoto={uid}
        isFirstPage={slug ? false : isFirstPageValue}
        nome={`Bem-vindo ${partesDoNome[0]}`}
        logoUrl={logoUrl}
        curso={curso}
      />
        <main className={`student-page_container-main ${curso}`} >
          {slug && (
            <Video
              lessonSlug={slug}
              urlPhoto={uid}
              userName={`Bem-vindo ${partesDoNome[0]}`}
              logoUrl={logoUrl}
              imgUrl={imgUrl}
              curso={curso}
            />
          )}
          {!slug && (
            <div className={`student-page_novideo ${curso}`}>
              <img className="img-enem" src={enem} alt="Galera do enem" />
              <p className={`student-page_novideo-description ${curso}`}>
                A matemática é mais do que imaginamos. Um bom conhecimento da
                matemática básica, nos traz um bom desempenho em outras matérias
                como, por exemplo, Química e Física. A correção do Enem acontece
                de acordo com a Teoria de Resposta do Item {"(TRI)"}. Isso
                significa que as perguntas são dividas entre fáceis, médias e
                difíceis e depois misturadas ao longo da prova. Através de
                estatísticas, as respostas dos alunos são analisadas. Caso o
                candidato tenha errado muitas questões fáceis e acertado as
                difíceis, o resultado é considerado improvável e por isso
                deduz-se ser fruto de chute. Assim, a média do aluno cai.
                Conclusão, o conhecimento básico da matemática é extremamente
                fundamental para um bom resultado no Enem. Pensando nisso, o
                <span>
                  {" "}
                  <a
                    href="https://blog.otaviorafael.com.br"
                    // className="hover:text-[#FFFF00]"
                    // target="_blank"
                  >
                    Blog Fonte do Saber
                  </a>{" "}
                </span>
                em parceria com o Fonte do Saber Cursos preparou tudo que você
                precisa saber de mais básico da Matemática que estará no Exame
                Nacional do Ensino Médio.
              </p>
              <div className="student-page_novideo-sub-description">
                <div className="sub-description_container">
                  <h1 className="title">Matemática Fundamental</h1>
                  <ul className="list">
                    <li>Tabuadinha Básica</li>
                    <li>Expressão numérica e Algébrica</li>
                    <li>Frações</li>
                    <li>Regra de Três</li>
                    <li>Porcentagens</li>
                    <li>Matemática Financeira</li>
                    <li>Equações</li>
                  </ul>

                  <h1 className="title">Matemática Elementar</h1>
                  <ul className="list">
                    <li>Função do 1º Grau (Afim)</li>
                    <li>Função do 2º Grau (Quadrática)</li>
                    <li>Sistemas Lineares</li>
                    <li>Análise Combinatória</li>
                    <li>Probabilidade</li>
                    <li>Estatística</li>
                    <li>Trigonometria</li>
                    <li>Geometria Plana</li>
                    <li>Geometria Espacial</li>
                    <li>Geometria Analítica</li>
                  </ul>
                </div>

                <div className="sub-description_container">
                  <h1 className="title">Vantagens do nosso curso:</h1>
                  <ul className="list">
                    <li>
                      Feedback das suas respostas nos simulados e exercícios;
                    </li>
                    <li>Comentários segundo os critérios do ENEM (TRI);</li>
                    <li>
                      Apostilas de acordo com ENEM e vestibulares do Brasil
                    </li>
                    <li>Acesso a questões extras gratuitamente;</li>
                    <li>Videoaulas de apoio aos conteúdos;</li>
                    <li>Chat exclusivo para dúvidas em grupo ou individual;</li>
                    <li>Acompanhamento completo da sua rotina de estudos.</li>
                  </ul>
                  <div className="pendulum">
                    <PendulumCard
                      title="AULAS LIBERADAS"
                      value="NO AR"
                      type="info"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {
            !slug && <Sidebar logoUrl={logoUrl} studentName={nome} curso={curso} />
          }
        </main>
      </div>
    </div>
  );
};

export default HomePageStudent;
