import React, { useState } from "react";
import { IconContext } from "react-icons";
import { BiError } from "react-icons/bi";
import { CardIconNoNavigationProps } from "../../interfaces/PropsModels";


const CardIconsNoNavigation = (props: CardIconNoNavigationProps) => {
  const [isHovered, setIsHovered] = useState(false);


  return (
    <IconContext.Provider value={{ color: "white", size: "2em" }}>
      <div
        className="dynamic-card"
        style={{
          backgroundColor: `${props.color}`,
          width: '140px',
          height: '140px',
          cursor: 'pointer',
          borderRadius: '8px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          alignItems: 'center',
          padding: '4px',
          fontSize: '2rem',
          transition: 'background-color 0.3s', // Adiciona uma transição suave para a mudança de cor
          boxShadow: isHovered ? '0 0 10px rgba(0, 0, 0, 0.3)' : 'none', // Adiciona uma sombra ao efeito hover
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {props.iconCode ? React.createElement(props.iconCode) : <BiError />}
        <p
        style={{
            fontSize: '1rem',
            margin: 0,
            fontWeight: 'bold',
            color: '#fff',
            textAlign: 'center'
          }}
        >{props.text}</p>
      </div>
    </IconContext.Provider>
  );
};

export default CardIconsNoNavigation;
