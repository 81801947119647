import { DefaultUi, Player, Youtube } from "@vime/react";
import { DiscordLogo, Lightning, FileArrowDown, Image } from "phosphor-react";
import "@vime/core/themes/default.css";
import { useEffect, useState } from "react";
import { getClassesFirestoreBySlug } from "../../databases/firestore";
import { ClassData } from "../../interfaces/Turmas";
import "./VideoComponent.scss";
import { useNavigate } from "react-router-dom";

interface VideoProps {
  lessonSlug: string;
  urlPhoto: string;
  userName: string;
  logoUrl: string;
  imgUrl: string;
  curso: string;
}

export function Video(props: VideoProps) {
  const navigate = useNavigate();

  const [lessonData, setLessonData] = useState<ClassData | any>();


  useEffect(() => {
    const classesData = async () => {
      try {
        let lesson = await getClassesFirestoreBySlug(props.lessonSlug, props.curso);

        setLessonData(lesson);
      } catch (error) {
        console.error(
          "Erro ao carregar as aulas cadastradas pelo professor:",
          error
        );
      }
    };

    classesData();
  }, [props.lessonSlug]);

  if (!lessonData) {
    return (
      <div className="wait-lesson">
        <p>Carregando...</p>
      </div>
    );
  }

  const handleSimuladoNavigate = () => {
    navigate("/app/students/logged/simulados", {
      state: {
        uid: props.urlPhoto,
        userName: props.userName,
        logoUrl: props.logoUrl,
        imgUrl: props.imgUrl,
        curso: props.curso,
      },
    });
  };

  return (
    <div className="video-page">
      <div className="video-page_container">
        <div className="video-page_player aspect-video">
          <Player>
            <Youtube
              videoId={
                props.lessonSlug === "id" ? "xuO0KCmj0-o" : lessonData.videoId
              }
            />
            <DefaultUi />
          </Player>
        </div>
      </div>

      <div className="video-page_container-description">
        <div className="video-page_container-description_container">
          <div className={`title ${props.curso}`}>
            <h1>{lessonData.title}</h1>
            <p
              className="description"
              dangerouslySetInnerHTML={{
                __html: lessonData.description ? (
                  lessonData.description
                ) : (
                  <p></p>
                ),
              }}
            ></p>

            {lessonData.teacher && (
              <div className="teacher-container">
                <img
                  className="teacher-container_photo"
                  src={lessonData.teacher.avatarUrl}
                  alt=""
                />

                <div className="leading-relaxed">
                  <strong className="teacher-name">
                    {lessonData.teacher.name}
                  </strong>
                  <span className="teacher-bio">{lessonData.teacher.bio}</span>
                </div>
              </div>
            )}
          </div>

          <div className="buttons-top_container">
            <a
              href="https://discord.gg/4hyAggTFUz"
              className="discord-button"
              target="_blank"
              rel="noreferrer"
            >
              <DiscordLogo size={24} />
              Comunidade do discord
            </a>

            <a
              href={lessonData.challenge?.url}
              className="desafio-button"
              target="_blank"
              rel="noreferrer"
            >
              <Lightning size={24} />
              Acesse o simulado
            </a>

            <a
              href={lessonData.apostilaUrl}
              className="desafio-button"
              target="_blank"
              rel="noreferrer"
            >
              <Lightning size={24} />
              Acesse a apostila
            </a>
          </div>
        </div>
        <div className="buttons-footer">
          {lessonData.challenge?.folderUrl && (
            <a
              href={lessonData.challenge?.folderUrl}
              className="material-button"
              target="_blank"
              rel="noreferrer"
            >
              <div className="material-button_icon">
                <FileArrowDown size={40} />
              </div>
              <div className="material-button_info">
                <strong className="material-button_info-title">
                  Material complementar
                </strong>
                <p className="material-button_info-desc">
                  Acesse o material complementar para acelerar o seu
                  desenvolvimento
                </p>
              </div>
              <div className="separador"></div>
            </a>
          )}
          <div
            className="material-button"
            style={{ cursor: "pointer" }}
            onClick={handleSimuladoNavigate}
          >
            <div className="material-button_icon">
              <Image size={40} />
            </div>
            <div className="material-button_info">
              <strong className="material-button_info-title">
                Resultado dos simulados
              </strong>
              <p className="material-button_info-desc">
                Acesse os resultados dos simulados de aula e os simulados
                exclusivos sobre esta aula
              </p>
            </div>
            <div className="separador"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
