import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './pages/Login/LoginPage';
import HomePage from './pages/Home/HomePage';
import HomePageAdm from './pages/Adms/HomeAdm/HomeAdm';
import HomePageStudent from './pages/Students/HomeStudent/HomeStudent';
import CreateUsers from './pages/Adms/CreateUsers/CreateUsers';
import CreateClasses from './pages/Adms/CreateClasses/CreateClasses';
import CreateSimulados from './pages/Adms/CreateSimulados/CreateSimulados';
import StudentSimulados from './pages/Students/StudentSimulados/StudentSimulados';
import StudentsReports from './pages/Adms/StudentsReports/StudentsReports';
import UploadPhoto from './components/UploadPhoto/UploadPhoto';
import CreateClassesCorreios from './pages/Adms/CreateClassesCorreios/CreateClassesCorreios';
import LoginCorreiosPage from './pages/LoginCorreios/LoginCorreiosPage';
import HomePageStudentCorreios from './pages/Students/HomeStudentCorreios/HomeStudent';

const App: React.FC = () => {
  return (
    <Router>
      <div className="app">
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/correios" element={<LoginCorreiosPage />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/app/adms/logged" element={<HomePageAdm />} />
          <Route path="/app/adms/logged/reports/students" element={<StudentsReports />} />
          <Route path="/app/adms/logged/adm/new/classes" element={<CreateClasses />} />
          <Route path="/app/adms/logged/adm/new/classes/correios" element={<CreateClassesCorreios />} />
          <Route path="/app/adms/logged/adm/new/users" element={<CreateUsers />} />
          <Route path="/app/adms/logged/adm/new/simulados" element={<CreateSimulados />} />
          <Route path="/app/students/logged" element={<HomePageStudent />} />
          <Route path="/app/students/correios/logged" element={<HomePageStudentCorreios />} />
          <Route path="/app/students/logged/change/photos" element={<UploadPhoto />} />
          <Route path="/app/students/logged/simulados" element={<StudentSimulados />} />
          <Route path="/app/students/logged/lesson/:slug" element={<HomePageStudent />} />
          <Route path="/app/students/correios/logged/lesson/:slug" element={<HomePageStudentCorreios />} />

        </Routes>
      </div>
    </Router>
  );
};

export default App;
