import React, { useEffect, useState } from 'react';
import LoginComponent from '../../components/Login/LoginComponent';
import logoMarca from '../../assets/images/orlam-solutions/Imagem6.png'
import './LoginCorreiosPage.scss'
import { getFileURL } from '../../databases/storage';
import logoCorreios from '../../assets/images/correios.png';
import bgCorreios from '../../assets/images/bg-login-correios.jpg';

const LoginCorreiosPage: React.FC = () => {
  const [logo, setLogo] = useState("");

  useEffect(() => {
    // Carrega a imagem de fundo ao montar o componente
    const loadBackgroundImage = async () => {
      try {
        const imageUrl = bgCorreios;
        // Aplique a URL no CSS
        document.documentElement.style.setProperty('--background-image', 'url(' + imageUrl + ')');
        
        const imageUrlMobile = bgCorreios
        document.documentElement.style.setProperty('--background-image-mobile', 'url(' + imageUrlMobile + ')');


        const imageLogoUrl = logoCorreios;

        setLogo(imageLogoUrl);

      } catch (error) {
        console.error("Erro ao carregar a imagem de fundo:", error);
      }
    };

    // Carrega a imagem de fundo ao montar o componente
    loadBackgroundImage();
  }, []);
  
  return (
    <div className="login-page">
      {logo && (
        <img src={logo} alt="logo matematica do zero" className='signup-logotipo'/>
      )}

      <LoginComponent logoUrl={logo} courseCode="correios"/>

      <div className="signup-logomarca">
            <a
              href="https://portfolio.or.app.br/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={logoMarca} alt="logomarca" className="logomarca" />
            </a>
          </div>
    </div>
  );
};

export default LoginCorreiosPage;
