import { storage } from "./firebaseConfig";

// Função para enviar um arquivo para o Storage
const uploadFile = (file: Blob | Uint8Array | ArrayBuffer, path: string) => {
  const storageRef = storage.ref();
  const fileRef = storageRef.child(path);

  return fileRef.put(file);
};

// Função para remover um arquivo do Storage
const deleteFile = (path: string) => {
  const storageRef = storage.ref();
  const fileRef = storageRef.child(path);

  return fileRef.delete();
};

// Função para obter a URL de um arquivo no Storage
const getFileURL = (path: string) => {
  const storageRef = storage.ref();
  const fileRef = storageRef.child(path);

  return fileRef.getDownloadURL();
};

export { uploadFile, deleteFile, getFileURL };
