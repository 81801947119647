import React, { useEffect, useState } from "react";
import "./HomeStudent.scss";
import HeaderComponent from "../../../components/HeaderComponent/HeaderComponent";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import enem from "../../../assets/images/galera-do-enem.png";
import { Video } from "../../../components/VideoComponent/VideoComponent";
import PendulumCard from "../../../components/PendulumCard/PendulumCard";
import { Sidebar } from "../../../components/Sidebar/Sidebar";
import { getFileURL } from "../../../databases/storage";
import { auth } from "../../../databases/firebaseConfig";
import correiosLogo from "../../../assets/images/correios.png"
import correiosBg from "../../../assets/images/bg-correios.jpg";
import correiosHorizontal from "../../../assets/images/logo-app.png"

const HomePageStudentCorreios: React.FC = () => {
  const navigate = useNavigate();
  const { slug } = useParams<{ slug: string }>();
  const { state } = useLocation();
  const uid = state?.uid || localStorage.getItem('code');
  const curso = state?.curso || 'enem';
  const role = state?.role ? state?.role : "student";
  const nome = state?.userName ? state?.userName : "Estudante";
  const partesDoNome = nome.split(" ");
  const logoUrl = state?.logoUrl;
  const [imgUrl, setImgUrl] = useState("");

  // Check if the user is already signed in
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) {
        navigate("/");
      }
    });

    // Cleanup the subscription when the component unmounts
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    // Check if the user is already signed in
    
    // Carrega a imagem de fundo ao montar o componente
    const loadBackgroundImage = async () => {
      try {
        const imageUrl = await getFileURL("students-page/background");
        // Aplique a URL no CSS
        document.documentElement.style.setProperty(
          "--background-image-student",
          "url(" + (curso === 'correios' ? correiosBg : imageUrl) + ")"
        );

        const imageUrlMobile = await getFileURL(
          "students-page/background-mobile"
        );
        document.documentElement.style.setProperty(
          "--background-image-mobile-student",
          "url(" + (curso === 'correios' ? correiosBg : imageUrlMobile) + ")"
        );

        curso === 'correios' ? setImgUrl(correiosBg) : setImgUrl(imageUrl);
      } catch (error) {
        console.error("Erro ao carregar a imagem de fundo:", error);
      }
    };

    // Carrega a imagem de fundo ao montar o componente
    loadBackgroundImage();
  }, []);

  const isFirstPageValue = role === 'adm' ? false : true;

  return (
    <div className="student-page">
      <div className={`student-page_container ${curso}`}>
      <HeaderComponent
        urlPhoto={uid}
        isFirstPage={slug ? false : isFirstPageValue}
        nome={`Bem-vindo ${partesDoNome[0]}`}
        logoUrl={curso === 'correios' ? correiosLogo : logoUrl}
        curso={curso}
      />
        <main className={`student-page_container-main ${curso}`} >
          {slug && (
            <Video
              lessonSlug={slug}
              urlPhoto={uid}
              userName={`Bem-vindo ${partesDoNome[0]}`}
              logoUrl={logoUrl}
              imgUrl={imgUrl}
              curso={curso}
            />
          )}
          {!slug && (
            <div className={`student-page_novideo ${curso}`}>
              <img className="img-correios" src={correiosHorizontal} alt="Galera dos correios" />
              <p className={`student-page_novideo-description ${curso}`}>
                Preparar-se para concursos é um grande passo rumo à estabilidade e ao crescimento profissional. Entre as matérias fundamentais para muitos concursos públicos, como o dos Correios, destacam-se a Matemática e a Informática. Compreender bem a matemática básica e desenvolver raciocínio lógico são pontos essenciais que ajudam não apenas nas questões específicas, mas também na resolução de problemas em diversas áreas de conhecimento. 

                Em Informática, o domínio das operações e funcionalidades básicas dos computadores é primordial para o dia a dia do trabalho. Saber utilizar planilhas, editores de texto e compreender os princípios de segurança da informação é crucial para um bom desempenho, tanto nas provas quanto nas futuras atividades práticas.

                Pensando nisso, o
                <span>
                  <a
                    href="https://blog.otaviorafael.com.br"
                    className="mx-2"
                    style={{ textDecoration: "none", color: "blue" }}
                    target="_blank"
                  >
                    Blog Fonte do Saber
                  </a>
                </span>
                em parceria com o Fonte do Saber Cursos organizou o conteúdo essencial de Matemática e Informática para que você possa dominar os temas mais cobrados nos concursos dos Correios e, assim, obter uma preparação completa e direcionada para o sucesso.
              </p>

              <div className="student-page_novideo-sub-description">
                <div className="sub-description_container">
                  <h1 className="title">MATEMÁTICA:</h1>
                  <ul className="list">
                    <li>Números inteiros: operações e propriedades; múltiplos e divisores: problemas.</li>
                    <li>Números racionais: operações e propriedades; problemas envolvendo as quatro operações na forma fracionária e decimal.</li>
                    <li>Números e grandezas proporcionais; razões e proporções; divisão proporcional; regra de três simples e composta.</li>
                    <li>Porcentagem.</li>
                    <li>Juros e desconto simples (juro, capital, tempo, taxa e montante).</li>
                    <li>Funções do 1º e 2º graus: problemas.</li>
                    <li>Sistema de medida: decimais e não decimais.</li>
                    <li>Sistema monetário brasileiro: problemas.</li>
                  </ul>

                  <h1 className="title">NOÇÕES DE INFORMÁTICA:</h1>
                  <ul className="list">
                    <li>Internet e Aplicativos.</li>
                    <li>Ferramentas de busca.</li>
                    <li>Navegadores (Browser).</li>
                    <li>Sistema Operacional e Software.</li>
                    <li>Correios Eletrônicos.</li>
                    <li>Programa Antivírus e Firewall.</li>
                    <li>Editores de Apresentação.</li>
                    <li>Editores de Planilhas.</li>
                    <li>Editores de Texto.</li>
                    <li>Extensão de Arquivo.</li>
                    <li>Teclas de Atalho.</li>
                    <li>Pacote Microsoft Office.</li>
                  </ul>
                </div>

                <div className="sub-description_container">
                  {/* <h1 className="title">Vantagens do nosso curso:</h1>
                  <ul className="list">
                    <li>
                      Feedback das suas respostas nos simulados e exercícios;
                    </li>
                    <li>Comentários segundo os critérios do ENEM (TRI);</li>
                    <li>
                      Apostilas de acordo com ENEM e vestibulares do Brasil
                    </li>
                    <li>Acesso a questões extras gratuitamente;</li>
                    <li>Videoaulas de apoio aos conteúdos;</li>
                    <li>Chat exclusivo para dúvidas em grupo ou individual;</li>
                    <li>Acompanhamento completo da sua rotina de estudos.</li>
                  </ul> */}
                  <div className="pendulum">
                    <PendulumCard
                      title="AULAS LIBERADAS"
                      value="29/10/2024"//"NO AR"
                      type="info"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {
            !slug && <Sidebar logoUrl={logoUrl} studentName={nome} curso={curso} />
          }
        </main>
      </div>
    </div>
  );
};

export default HomePageStudentCorreios;
