import React from 'react';
import './PendulumCard.scss';

interface CardProps {
  title: string;
  value: string;
  type: string;
}

const PendulumCard = (props: CardProps) => {
  return (
    <div className={`pendulum-card-${props.type}`}>
      <div className={`circle-${props.type}`}></div>
      <div className={`pendulum-content-${props.type}`}>
        <div className={`pendulum-text-${props.type}`}>
          {props.title}
        </div>
        <div className={`pendulum-amount-${props.type}`}>
          <span className={`flashing-text-${props.type}`}>{props.value}</span>
        </div>
      </div>
    </div>
  );
};

export default PendulumCard;
