import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./StudentsReports.scss";
import loading from '../../../assets/images/background-default.gif'
import { UserData } from "../../../interfaces/Students";
import { getUsersFirestore } from "../../../databases/firestore";
import { auth } from "../../../databases/firebaseConfig";
import { signOut } from "../../../utils/SingOut";
import HeaderComponent from "../../../components/HeaderComponent/HeaderComponent";
import TableStudents from "../../../components/DataTableExcel/TableStudents/TableStudents";

const StudentsReports: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const role = state?.role;
  const uid = state?.uid || localStorage.getItem('code');
  const logoUrl = state?.logoUrl;
  const imgUrl = state?.imgUrl ? state?.imgUrl : loading;

  const [usuarios, setUsuarios] = useState<UserData[]>([]);

  // Função para carregar os cursos ao montar o componente
  useEffect(() => {
    const loadStudents = async () => {
      try {
        const usersData = await getUsersFirestore();
        setUsuarios(usersData);
        setFilteredStudents(usersData);
      } catch (error) {
        console.error("Erro ao carregar usuários:", error);
      }
    };

    //Carrega apenas uma vez a chamada;
    loadStudents();
  }, []);

  // Check if the user is already signed in
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) {
        navigate("/");
      }
    });

    if (role === "student") {
      handleLogout();
    }
    
    // Carrega a imagem de fundo ao montar o componente
    document.body.style.backgroundImage = `url(${imgUrl})`;

    // Cleanup the subscription when the component unmounts
    return () => unsubscribe();
  }, []);

  const handleLogout = async () => {
    try {
      await signOut();
    } catch (error: any) {
      console.error("Erro ao fazer logout:", error.message);
    }
  };

  const [selectedUserType, setSelectedUserType] = useState("");
  const [filteredStudents, setFilteredStudents] = useState(usuarios);

  const uniqueUserTypes = Array.from(
    new Set(usuarios.map((user) => user.role))
  );

  const handleChange = (event: any) => {
    const selectedUserTypeCode = event.target.value;
    setSelectedUserType(selectedUserTypeCode);

    if (selectedUserTypeCode === "") {
      setFilteredStudents(usuarios);
    } else {
      const filtered = usuarios.filter(
        (users) => users.role === selectedUserTypeCode
      );
      setFilteredStudents(filtered);
    }
  };

  return (
    <div className="adms-page-create">
      <HeaderComponent isFirstPage={false} nome={`Bem-vindo ${role}`} logoUrl={logoUrl} urlPhoto={uid}/>

      <h1 style={{ color: "#fff" }}>LISTA DOS CADASTRADOS</h1>

      <div
        style={{
          width: "85%",
          backgroundColor: "#fff",
          borderRadius: "8px",
          padding: "20px",
        }}
      >
        <label
          htmlFor="courseSelect"
          style={{ marginRight: "6px", fontWeight: "bold" }}
        >
          Escolha um tipo para filtrar:
        </label>
        <select
          id="courseSelect"
          value={selectedUserType}
          onChange={handleChange}
          style={{ borderRadius: "4px", border: "1px solid #5c5c5c", width: "100%", maxWidth: '900px' }}
        >
          <option value="" style={{fontWeight: 'bold'}}>TODOS</option>
          {uniqueUserTypes.map((userTypeCode) => (
            <option key={userTypeCode} value={userTypeCode} style={{fontWeight: 'bold'}}>
              {`USUÁRIOS ${userTypeCode.toUpperCase()} `}
            </option>
          ))}
        </select>

        <p
          style={{
            fontSize: "24px",
            width: "100%",
            textAlign: "center",
            margin: "20px",
            fontWeight: "bold",
            color: "blue",
          }}
        >{`${filteredStudents.length} cadastrados desse tipo`}</p>

        {/* DataTable usando os alunos filtrados */}
        <TableStudents rows={filteredStudents} />
      </div>
    </div>
  );
};

export default StudentsReports;
