import { useEffect, useState } from "react";
import { ClassData } from "../../interfaces/Turmas"
import { Lesson } from "../Lesson/Lesson"
import { getClassesFirestore } from "../../databases/firestore";
import './Sidebar.scss'

interface SidebarProps {
  logoUrl: string;
  studentName: string;
  curso: string;
}

export function Sidebar(props: SidebarProps){
  const [lessonsData, setLessonsData] = useState<ClassData[]>([]);

  useEffect(() => {
    const classesData = async () => {
      try {
        let classes = await getClassesFirestore(props.curso);

        // Ordenando o array pelo campo availableAt
        classes.sort((a, b) => {
          let dateA: any = new Date(a.availableAt);
          let dateB: any = new Date(b.availableAt);
          return dateA - dateB; 
        });

        setLessonsData(classes);
        //localStorage.setItem("classes", JSON.stringify(classes));
        //console.log(classes)
      } catch (error) {
        console.error(
          "Erro ao carregar as aulas cadastradas pelo professor:",
          error
        );
      }
    };

    classesData();
  }, []);

    return(
        <div className={`sidebar-container ${props.curso}`}>
            <span className="sidebar-container_title">
                Cronograma de aulas
            </span>

            <div className="sidebar-container_card">
                {lessonsData?.map((lesson: ClassData) => {
                    return (
                        <Lesson 
                            key={lesson.id}
                            title={lesson.title}
                            slug={lesson.slug}
                            availableAt={new Date(lesson.availableAt)}
                            type={lesson.lessonType}
                            logoUrl={props.logoUrl}
                            studentName={props.studentName}
                            curso={props.curso}
                        />
                    )
                })}
            </div>
        </div>
    )
}