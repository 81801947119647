import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import loading from "../../../assets/images/background-default.gif";
import "./CreateSimulados.scss";
import { v4 as uuidv4 } from "uuid";
import AlertModal from "../../../components/AlertModal/AlertModal";
import { auth } from "../../../databases/firebaseConfig";
import { signOut } from "../../../utils/SingOut";
import HeaderComponent from "../../../components/HeaderComponent/HeaderComponent";
import { QuestaoData, SimuladoData } from "../../../interfaces/Simulados";
import {
  addSimuladoFirestore,
  getSimuladosFirestore,
  removeSimuladoFirestore,
} from "../../../databases/firestore";

const CreateSimulados: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const role = state?.role;
  const uid = state?.uid || localStorage.getItem('code');
  const logoUrl = state?.logoUrl;
  const imgUrl = state?.imgUrl ? state?.imgUrl : loading;

  const [simulados, setSimulados] = useState<SimuladoData[]>([]);
  const [title, setTitle] = useState("");
  const [numQuestions, setNumQuestions] = useState(1);
  const [maxScore, setMaxScore] = useState(1);
  const [gabarito, setGabarito] = useState<QuestaoData[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  // Check if the user is already signed in
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) {
        navigate("/");
      }
    });

    if (role === "student") {
      handleLogout();
    }

    // Carrega a imagem de fundo ao montar o componente
    document.body.style.backgroundImage = `url(${imgUrl})`;

    // Carrega a lista de simulados ao montar o componente
    loadSimulados();

    // Cleanup the subscription when the component unmounts
    return () => unsubscribe();
  }, []);

  const loadSimulados = async () => {
    try {
      const simuladosData = await getSimuladosFirestore();
      // Ordena os simulados por title
      simuladosData.sort((a, b) => a.title.localeCompare(b.title));
      setSimulados(simuladosData);
    } catch (error: any) {
      console.error("Erro ao carregar simulados:", error.message);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut();
    } catch (error: any) {
      console.error("Erro ao fazer logout:", error.message);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setError("");
    setSuccessMessage("");
    setTitle("");
    setNumQuestions(1);
    setMaxScore(1);
    setGabarito([]);
  };

  const handleCreateSimulado = async () => {
    const simuladoData: SimuladoData = {
      id: uuidv4(),
      title,
      numQuestions,
      maxScore,
      gabarito,
    };

    try {
      await addSimuladoFirestore(simuladoData);
      setSuccessMessage("Cadastro do simulado realizado com sucesso!");
      setShowModal(true);
      loadSimulados(); // Atualiza a lista de simulados após a criação
    } catch (error: any) {
      setError(error.message);
      setShowModal(true);
    }
  };

  const handleRemoveSimulado = async (simuladoId: string) => {
    try {
      await removeSimuladoFirestore(simuladoId);
      setSuccessMessage("Simulado removido com sucesso!");
      setShowModal(true);
      loadSimulados(); // Atualiza a lista de simulados após a remoção
    } catch (error: any) {
      setError(error.message);
      setShowModal(true);
    }
  };

  const handleAddQuestao = (
    numero: number,
    gabarito: string,
    pontuacao: number
  ) => {
    setGabarito((prevGabarito) => {
      // Verificar se a questão já existe no gabarito
      const questaoExistenteIndex = prevGabarito.findIndex(
        (questao) => questao.numero === numero
      );

      // Se a questão já existe, atualizar o gabarito, caso contrário, adicionar uma nova questão
      if (questaoExistenteIndex !== -1) {
        // Questão já existe, atualizar o gabarito e a pontuação
        const novoGabarito = [...prevGabarito];
        novoGabarito[questaoExistenteIndex] = {
          numero,
          gabarito,
          pontuacao,
        };
        return novoGabarito;
      } else {
        // Questão não existe, adicionar uma nova questão
        return [...prevGabarito, { numero, gabarito, pontuacao }];
      }
    });
  };
  

  return (
    <div className="adms-page-create">
      <HeaderComponent
        isFirstPage={false}
        nome={`Bem-vindo ${role}`}
        logoUrl={logoUrl}
        urlPhoto={uid} 
      />

      <div className="card-container-users">
        <form className="form-signup new-simulado was-validated">
          <div className="input-group mb-3">
            <label style={{ width: "100%", fontWeight: "bold" }}>
              Título do Simulado:
              <input
                type="text"
                className="form-control"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
                title="Insira o título do simulado"
              />
            </label>
          </div>

          <div className="input-group mb-3">
            <label style={{ width: "100%", fontWeight: "bold" }}>
              Quantidade de Questões:
              <input
                type="number"
                className="form-control"
                value={numQuestions}
                onChange={(e) => setNumQuestions(Number(e.target.value))}
                min={1}
                max={100}
                required
              />
            </label>
          </div>

          <div className="input-group mb-3">
            <label style={{ width: "100%", fontWeight: "bold" }}>
              Pontuação Máxima:
              <input
                type="number"
                className="form-control"
                value={maxScore}
                onChange={(e) => setMaxScore(Number(e.target.value))}
                min={1}
                max={1000}
                required
              />
            </label>
          </div>

          {[...Array(numQuestions)].map((_, index) => (
            <div key={index} className="questao-inputs">
              <span>Questão {index + 1}: </span>
              <label>
                Resposta correta:
                <select
                  className="form-control"
                  value={gabarito[index]?.gabarito || ""}
                  onChange={(e) =>{
                    handleAddQuestao(
                      index + 1,
                      e.target.value,
                      maxScore / numQuestions
                    )
                  }}
                  required
                >
                  <option value="">Escolha uma opção</option>
                  <option value="A">A</option>
                  <option value="B">B</option>
                  <option value="C">C</option>
                  <option value="D">D</option>
                  <option value="E">E</option>
                </select>
              </label>
              <label style={{ margin: "0 10px" }}>
                Pontuação:
                <input
                  type="number"
                  className="form-control"
                  value={gabarito[index]?.pontuacao || 0}
                  onChange={(e) => {
                    const pontuacao = Number(e.target.value);
                    handleAddQuestao(
                      index + 1,
                      gabarito[index]?.gabarito || "",
                      pontuacao
                    );
                  }}
                  min={0}
                  max={maxScore / numQuestions}
                  required
                  disabled
                />
              </label>
            </div>
          ))}

          <div className="buttons-container">
            <button
              className="btn btn-primary adm-button"
              type="button"
              onClick={handleCreateSimulado}
              style={{ marginTop: "20px" }}
            >
              CONCLUIR CADASTRO DO SIMULADO
            </button>
          </div>
        </form>

        {showModal && successMessage && (
          <AlertModal message={successMessage} onClose={handleCloseModal} />
        )}

        {showModal && error && (
          <AlertModal message={error} onClose={handleCloseModal} />
        )}

        <div className="create-simulados-list" style={{ color: "#fff" }}>
          {simulados.length > 0 && (
            <>
              <h3 style={{ color: "#fff", marginTop: "20px", textAlign: 'center' }}>
                Lista de Simulados
              </h3>
              <ul>
                {simulados.map((simulado) => (
                  <li key={simulado.id}>
                    <span>{simulado.title}</span>
                    <button
                      type="button"
                      onClick={() => handleRemoveSimulado(simulado.id)}
                      className="btn btn-warning"
                    >
                      Remover
                    </button>
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateSimulados;
